<script lang="ts">
  import { session } from "$app/stores";

  import { app_version } from "$lib/utils";
</script>

<footer class="footer">
  <div class="content has-text-centered">
    <p>
      {#if $session?.public_user}
        User
        <strong>{$session?.public_user?.email}</strong>
        |
      {/if}
      Version <strong>{app_version()}</strong>
    </p>
  </div>
</footer>

<style>
</style>
