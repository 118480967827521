<script lang="ts" context="module">
  import { supabase } from "$lib/data/supabase";

  import type { Load } from "@sveltejs/kit";
  import "$lib/network/sentry";

  export const load: Load = async function ({ page, session, stuff, fetch }) {
    if (browser) {
      const re = getParameterByName("error_description");
      if (re) {
        const route = route_for(Route.link_problem);
        console.log("route", route);
        return {
          status: 302,
          redirect: route,
        };
      }
    }

    // Configure supabase state listeners
    supabase.auth.onAuthStateChange(async (event, sb_session) => {
      if (event === "PASSWORD_RECOVERY") {
        debug(
          "supabase.auth.onAuthStateChange (caught password_recovery link, navigate to /reset_password)",
          event,
          sb_session
        );

        set_client_cookie(sb_session);
        set_sentry_user();

        await tick();
        window.location.pathname = route_for(Route.reset_password);
      } else if (event === "SIGNED_IN") {
        debug(
          "supabase.auth.onAuthStateChange (incl via magic_link)",
          event,
          sb_session
        );

        set_client_cookie(sb_session);
        set_sentry_user();

        await tick();
        window.location.pathname = route_for(Route.runs);
      } else if (event === "SIGNED_OUT") {
        debug(
          "supabase.auth.onAuthStateChange (doing nothing in subscriber)",
          event,
          sb_session
        );

        unset_client_cookie();
        set_sentry_user();

        await tick();
        window.location.pathname = route_for(Route.public);
      }
    });

    return {};
  };

  function getParameterByName(arg0: string) {
    const url = window.location.href;
    const name = arg0.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
</script>

<script lang="ts">
  import "$lib/css/style.scss";

  import Header from "$lib/components/Layout/Header.svelte";
  import Footer from "$lib/components/Layout/Footer.svelte";
  import Loading from "$lib/components/Layout/Loading.svelte";

  import { debug } from "$lib/debug";
  import { Route, route_for } from "$lib/route_list";
  import { set_client_cookie, unset_client_cookie } from "$lib/cookies";
  import { set_sentry_user } from "$lib/network/sentry";
  import { tick } from "svelte";
  import { browser } from "$app/env";
  import { toast } from "@zerodevx/svelte-toast";
</script>

<Loading />
<Header />
<slot />
<Footer />
