<script lang="ts">
  import { session } from "$app/stores";

  import { Route, route_for } from "$lib/route_list";
  import AdminPowerDebug from "./AdminPowerDebug.svelte";

  let menu_active = false;
</script>

<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a
      class="navbar-item"
      href={$session ? route_for(Route.runs) : route_for(Route.public)}
    >
      <img src="/favicon.svg" width="52" height="28" alt="logo" />
      <p class="title">Locational Algorithm Runner</p>
    </a>
    <!-- svelte-ignore a11y-missing-attribute -->
    <a
      role="button"
      class="navbar-burger"
      class:is-active={menu_active}
      aria-label="menu"
      aria-expanded="false"
      on:click={() => (menu_active = !menu_active)}
    >
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </a>
  </div>

  <div class="navbar-menu" class:is-active={menu_active}>
    <div class="navbar-start">
      <a href={route_for(Route.docs)} target="_blank" class="navbar-item">
        <span>Docs</span>
      </a>
    </div>
    <div class="navbar-end">
      <AdminPowerDebug />
      <!-- Login button -->
      {#if !$session?.public_user}
        <a href={route_for(Route.login)} class="navbar-item">Login</a>
      {:else}
        <a href={route_for(Route.runs)} class="navbar-item">
          <span>Runs</span>
        </a>
        <div class="navbar-item has-dropdown is-hoverable">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a class="navbar-link">
            {$session?.public_user?.email || "User"}
          </a>

          <div class="navbar-dropdown is-right ">
            <!-- svelte-ignore a11y-missing-attribute -->
            <a class="navbar-item" href={route_for(Route.reset_password)}>
              Reset password
            </a>
            <hr class="navbar-divider" />
            <a href={route_for(Route.logout)} class="navbar-item">Logout</a>
          </div>
        </div>
      {/if}
    </div>
  </div>
</nav>

<style lang="scss">
  nav {
    background: white;
  }

  .title {
    color: black;
    font-size: 1.1rem;
  }
</style>
