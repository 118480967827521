<script>
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import { toast } from "@zerodevx/svelte-toast";

  import { loading, loading_entries } from "$lib/stores/loading_store";
  import { debug } from "$lib/debug";

  let toast_id = null;

  function toast_the_load() {
    toast_id = toast.push("Loading...", {
      initial: 0,
      progress: 0,
      dismissable: false,
      theme: {
        "--toastBackground": "#48BB78",
        "--toastProgressBackground": "#2F855A",
      },
    });
  }

  $: {
    if ($loading) {
      toast_the_load();
    } else {
      toast.pop(toast_id);
      toast_id = null;
    }
  }

  $: debug("$loading_entries", $loading_entries);
</script>

<SvelteToast />

<style>
  :global(:root) {
    --toastContainerTop: 5rem;
  }
</style>
