<script lang="ts">
  import { session } from "$app/stores";
  import { supabase } from "$lib/data/supabase";

  import { clear_cache } from "$lib/network/cache";
  import { Route, route_for } from "$lib/route_list";
  import {
    active_parcel_files,
    algorithm,
    algo_use,
  } from "$lib/stores/file_store";
  import { debug_flags } from "$lib/stores/flags";
  import { loading_entries } from "$lib/stores/loading_store";

  /**
   * These watchers are a little nasty, but they work: we want to observe the direction of changes in value of each flag, and
   * putting them inside a 'subscribe' on the store stops us being able to observe the individual previous values - we can only ever
   * tell whether they are true/false right now, and now whether they have just become true/false
   */
  let debug_flag_on_load = $debug_flags.debug;
  let cache_flag_on_load = $debug_flags.cache;

  function clear_debug_flags() {
    $debug_flags.cache = false;
    $debug_flags.debug = false;
    $debug_flags.power = false;
  }

  $: {
    if ($debug_flags.debug && $debug_flags.debug !== debug_flag_on_load) {
      console.warn(
        "Please refresh page: $debug_flags.debug is now enabled, but the `debug` function needs a reload to work properly"
      );
    }
  }
  $: {
    if (!$debug_flags.cache && $debug_flags.cache !== cache_flag_on_load) {
      clear_cache();
    }
  }

  function log_session() {
    console.log("session", $session);
    console.log("supabase session", supabase.auth.session());
    console.log("debug_flags", $debug_flags);
    console.log("active_parcel_files", $active_parcel_files);
    console.log("loading_entries", $loading_entries);
    console.log("algo_use", $algo_use);
    console.log("algorithm", $algorithm);
  }
</script>

{#if $session?.public_user?.admin}
  <a href={route_for(Route.admin)} target="_blank" class="navbar-item">
    <span>Admin</span>
    <span class="icon">
      <i class="fas fa-external-link-alt" />
    </span>
  </a>
{/if}

{#if $session?.public_user?.power}
  <div class="navbar-item has-dropdown is-hoverable">
    <!-- svelte-ignore a11y-missing-attribute -->
    <a
      class="navbar-link"
      class:is-active-power={$debug_flags.power ||
        $debug_flags.debug ||
        $debug_flags.cache}
    >
      Power
    </a>

    <div class="navbar-dropdown">
      <!-- svelte-ignore a11y-missing-attribute -->
      <a
        class="navbar-item"
        class:is-active-power={$debug_flags.power}
        on:click={() =>
          ($debug_flags.power = !$debug_flags.power ? true : undefined)}
        >Power mode</a
      >
      <!-- svelte-ignore a11y-missing-attribute -->
      <a
        class="navbar-item"
        class:is-active-power={$debug_flags.debug}
        on:click={() =>
          ($debug_flags.debug = !$debug_flags.debug ? true : undefined)}
        >Debug</a
      >
      <!-- svelte-ignore a11y-missing-attribute -->
      <a
        class="navbar-item"
        class:is-active-power={$debug_flags.cache}
        on:click={() =>
          ($debug_flags.cache = !$debug_flags.cache ? true : undefined)}
        >Cache</a
      >

      <!-- svelte-ignore a11y-missing-attribute -->
      <a class="navbar-item" on:click={clear_debug_flags}>Clear all powers</a>
      <hr class="navbar-divider" />
      <!-- svelte-ignore a11y-missing-attribute -->
      <a class="navbar-item" on:click={log_session}>Log session</a>
    </div>
  </div>
{/if}

<style>
  :global(.is-active-power) {
    color: green;
    background-color: yellowgreen;
  }
</style>
